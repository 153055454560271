import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Container from '../components/Container';
import Row from '../components/Container/row';
import Col from '../components/Container/column';

import '../components/ProductCategory/apply.css';

import loadComponents from '../components/Loadable';
import loadLibrary from '../components/Loadable/library';

const Img = loadLibrary('gatsby-image');

const SubpageHeader = loadComponents('subpage-header');
const Link = loadComponents('link');

export default function ApplyNow({ data, location }) {
  const page = {
    name: 'Apply Now',
    title: `Where are you travelling to?`,
    slug: 'apply-now',
  };
  const prevPage = location.state && location.state.prevPage;
  const categories = data.allWpProductCategory.edges;
  return (
    <Layout title={page.name} prevPage={prevPage} padding="0 0 5.625rem">
      <SubpageHeader title={page.title} />
      <Container>
        <Row className="categorySelect">
          {categories.map(item => (
            <Col
              key={item.node.name}
              className="categoryItem"
              xl={{ width: `20%` }}
              md={{ width: `33.333%` }}
              sm={{ width: `50%` }}
            >
              <Link
                to={item.node.link}
                label={item.node.name}
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  justifyContent: `center`,
                }}
              >
                <div
                  style={{
                    height: `150px`,
                    width: `150px`,
                    borderRadius: `150px`,
                    overflow: `hidden`,
                    marginBottom: `20px`,
                    border: `1px solid rgba(0,0,0,0.1)`,
                  }}
                >
                  <Img
                    image={
                      item?.node?.image?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    alt={item.node.name}
                    style={{ height: `100%` }}
                  />
                </div>
                <strong>{item?.node?.name}</strong>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
}

export const ApplyNowData = graphql`
  query ApplyNowQuery {
    allWpProductCategory(filter: { databaseId: { ne: 27 } }) {
      edges {
        node {
          name
          link
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  height: 300
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
